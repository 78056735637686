
import { Flex, useBreakpointValue, IconButton, Icon, useColorMode, HStack, LightMode, Button, DarkMode, Spacer, Divider, Box } from '@chakra-ui/react';
import { Profile } from '../Header/Profile';
import { Notifications } from '../Header/Notifications';
import { useSidebarDrawer } from '../../contexts/SidebarDrawerContext';
import { RiMenuLine } from 'react-icons/ri';
import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import { Search } from '../Search';
import { Logo } from '../../public/logo';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/auth/authContext';
import Router from 'next/router';
import { TabsCustom } from '../Tabs';

export function Header() {
  const { colorMode, toggleColorMode } = useColorMode()
  const { autenticado } = useContext(AuthContext);
  const { onOpen } = useSidebarDrawer()
  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true,
  });

  function handleEntrar() {
    Router.push('/login');
  }
  return (
    <Flex
      // align="center"
      as="header"
      justify="space-between"
      bg="#202f49"
    // w="100%"
    // mx="auto"
    // h="8rem"
    // px="6"
    // align="space-between"
    // alignContent="space-between"
    // shadow='lg'
    >

      <Logo />
      {/* <Spacer /> */}
      {/* <Search /> */}
      {/* <Spacer /> */}
      <HStack align="center">

        {
          autenticado &&
          <Flex align="center" ml="auto">
            <Profile showProfileData={isWideScreen} />
          </Flex>
        }
        {/* {
          !autenticado &&
          <Button onClick={handleEntrar}>Entrar</Button>
        }

        <HStack>
          {
            colorMode == 'dark' ?
              <IconButton variant='unstyled' size='sm' colorScheme='blue' onClick={toggleColorMode} aria-label='Search database' icon={<MoonIcon />} />
              :
              <IconButton variant='unstyled' size='sm' colorScheme='blue' onClick={toggleColorMode} aria-label='Search database' icon={<SunIcon />} />

          }
        </HStack> */}
      </HStack>

    </Flex>
  )
}

