import type { GetServerSideProps, NextPage } from 'next'
import { FormEvent, ReactNode, useState } from "react";
import {
  Flex,
  Input,
  Button,
  Stack,
  Text,
  chakra,
  SimpleGrid,
  Box,
  VStack,
  Heading,
  HStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useContext } from 'react';
import { AuthContext } from '../contexts/auth/authContext';
import { SSRGuest } from '../utils/SSRGuest';
import { parseCookies } from 'nookies';
import Router from "next/router";
import { Header } from '../components/Header';
import { FaCheckCircle } from 'react-icons/fa';
import Chamada1 from '../components/Chamada1';

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);


function PriceWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'}>
      {children}
    </Box>
  );
}


const Home: NextPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const { autenticar, isLoading, message, clearMessage } = useContext(AuthContext)

  const handleShowClick = () => setShowPassword(!showPassword);

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    const data = {
      username,
      password
    }
    if (data.username && data.password) {
      await autenticar(data);
    } else {
      return
    }
  }

  function handleSetPassword(value) {
    setPassword(value);
    clearMessage();
  }

  function handleSetUsername(value) {
    setUsername(value.toLowerCase());
    clearMessage();

  }


  return (
    <SimpleGrid columns={1} spacing={2}>
      <Header />
      <Chamada1 />
      {/* <Box py={12}>
        <VStack spacing={2} textAlign="center">
          <Heading as="h1" fontSize="4xl">
            Ventagens dos assinantes
          </Heading>
          <Text fontSize="lg" color={'gray.500'}>
            Comece com um teste gratuito de 14 dias. Não é necessário cartão de crédito. Cancele a qualquer momento.
          </Text>
        </VStack>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          textAlign="center"
          justify="center"
          spacing={{ base: 4, lg: 10 }}
          py={10}>
          <PriceWrapper>
            <Box py={4} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                Básico
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  R$
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  79
                </Text>
                <Text fontSize="3xl" color="gray.500">
                  /Ano
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue('gray.50', 'gray.700')}
              py={4}
              borderBottomRadius={'xl'}>
              <List spacing={3} textAlign="start" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Acesso a todo conteúdo das decisões.
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Histórico de suas pesquisas.
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Favoritos.
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <Button w="full" colorScheme="red" variant="outline">
                  Começar
                </Button>
              </Box>
            </VStack>
          </PriceWrapper>

          <PriceWrapper>
            <Box position="relative">
              <Box
                position="absolute"
                top="-16px"
                left="50%"
                style={{ transform: 'translate(-50%)' }}>
                <Text
                  textTransform="uppercase"
                  bg={useColorModeValue('red.300', 'red.700')}
                  px={3}
                  py={1}
                  color={useColorModeValue('gray.900', 'gray.300')}
                  fontSize="sm"
                  fontWeight="600"
                  rounded="xl">
                  Mais popular
                </Text>
              </Box>
              <Box py={4} px={12}>
                <Text fontWeight="500" fontSize="2xl">
                  Avançado
                </Text>
                <HStack justifyContent="center">
                  <Text fontSize="3xl" fontWeight="600">
                    R$
                  </Text>
                  <Text fontSize="5xl" fontWeight="900">
                    149
                  </Text>
                  <Text fontSize="3xl" color="gray.500">
                    /Ano
                  </Text>
                </HStack>
              </Box>
              <VStack
                bg={useColorModeValue('gray.50', 'gray.700')}
                py={4}
                borderBottomRadius={'xl'}>
                <List spacing={3} textAlign="start" px={12}>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Acesso a todo conteúdo das decisões
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Histórico de suas pesquisas.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Favoritos.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Ranqueamento com uso de inteligência artificial.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Salve comentários.
                  </ListItem>
                </List>
                <Box w="80%" pt={7}>
                  <Button w="full" colorScheme="red">
                    Começar
                  </Button>
                </Box>
              </VStack>
            </Box>
          </PriceWrapper>
        </Stack>
      </Box> */}


    </SimpleGrid>


  );
}

export default Home

export const getServerSideProps = SSRGuest(async (ctx) => {

  return {
    props: {}
  }
})

