import { Flex, Text, Box, Avatar, IconButton, HStack, Stack, Button, ButtonGroup } from '@chakra-ui/react';
import { useContext } from 'react';
import { AiOutlineLogout } from 'react-icons/ai';
import { AuthContext } from '../../contexts/auth/authContext';

interface ProfileProps {
    showProfileData: boolean;
}

export function Profile({ showProfileData = true }: ProfileProps) {
    const { usuario, desconectar } = useContext(AuthContext);
    return (
        <Flex align="center">
            {showProfileData && (
                <Box mr="4" textAlign="right">
                    <Text>{usuario?.first_name}</Text>
                    <Text color="gray.300" fontSize="small">
                        {usuario?.last_name}
                    </Text>
                </Box>
            )}
            <Avatar size="md" name={usuario?.first_name} />
            <HStack ml="15px">
                <ButtonGroup size='sm' isAttached variant='unstyled' >
                    <Button onClick={desconectar} mr='-px'>Sair</Button>
                    <IconButton fontSize='20px' aria-label='Sair' icon={<AiOutlineLogout />} />
                </ButtonGroup>
            </HStack>
        </Flex>
    );
}
